import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {
    XFormColumn,
    XFormDataTable2,
    XFormTextareaColumn
} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {EnumEnum, Param, Rola, SluzbaEnum} from "../../common/enums";
import {XDropdownEnum} from "../user/XDropdownEnum";
import {Utils} from "../../Utils";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {Klient} from "../../model/klient/klient.entity";
import {dateAsDB, dateFromModel, stringAsUI, XDateScale} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XFormCol} from "@michalrakus/x-react-web-lib/XFormCol";
import {XFormRow} from "@michalrakus/x-react-web-lib/XFormRow";
import {XFormInlineRow} from "@michalrakus/x-react-web-lib/XFormInlineRow";
import {XInputTextarea} from "@michalrakus/x-react-web-lib/XInputTextarea";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {UtilsCommon} from "../../common/UtilsCommon";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XFormScrollable} from "../XFormScrollable";
import {KlientAdresa} from "./KlientAdresa";
import {statSlovensko} from "../../common/constants";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";

interface ZakazDatumy {
    datumOd: Date;
    datumDo: Date;
}

@Form("Klient")
export class KlientNoclaharenForm extends XFormBaseModif {

    private predplateneNoci: number | undefined = undefined;
    private zakazyMapFromEditStart: Map<number, ZakazDatumy> | undefined = undefined;

    // promise, pomocou ktoreho v onClickSave/onClickCancel cakame na vysledky onChange (onChange vola backend)
    onChangePromise: Promise<boolean> | undefined = undefined;

    constructor(props: XFormProps) {
        super(props);

        this.addField("obec.okres.nazov"); // chceme vidiet aj okres v autocomplete

        this.onChangeMenoPriezviskoDatumNarodenia = this.onChangeMenoPriezviskoDatumNarodenia.bind(this);
    }

    async componentDidMount() {
        // niekedy potrebujeme v preInitForm, preto si dopredu nacitame
        // TODO - ak by sme vedeli parametre inac predcitat, tak si mozme tento request usetrit
        this.predplateneNoci = await Utils.getXParamValueAsInt(Param.n_novyKlientPredplateneNoci);

        await super.componentDidMount(); // tu sa vola createNewObject a preInitForm
    }

    createNewObject(): XObject {
        return {
            datumNarodeniaIbaRok: false,
            datumNarodeniaOverenyUdaj: false,
            rodneCisloOverenyUdaj: false,
            statCiselnik: statSlovensko,
            mesto: null,
            obec: null,
            klientPrijemList: [],
            klientZakazUbytovatList: [],
            predplateneNoci: this.predplateneNoci,
            uzivatel: false,
            noclaharen: true,
            version: 0
        };
    }

    preInitForm(klient: Klient, operationType: OperationType.Insert | OperationType.Update) {
        // ulozime si hodnoty datumov zo zakazov zo zaciatku editacie
        this.zakazyMapFromEditStart = new Map<number, ZakazDatumy>();
        for (const klientZakazUbytovat of klient.klientZakazUbytovatList) {
            this.zakazyMapFromEditStart.set(klientZakazUbytovat.id, {datumOd: klientZakazUbytovat.datumOd, datumDo: klientZakazUbytovat.datumDo});
        }

        // ak otvarame klienta vyhladaneho cez KlientNoclaharenAutoComplete tak je to klient z cudzej sluzby a treba mu nastavit priznak noclaharen
        if (!klient.noclaharen) {
            klient.noclaharen = true;
            // a pridame aj predplatene noci
            klient.predplateneNoci = this.predplateneNoci!;
        }
    }

    // pomocna metodka
    private getKlient(): Klient | null {
        return this.state.object;
    }

    // zimplementovane analogicky k metode KlientSluzbaForm.onChangeKlientMenoPriezviskoDatumNarodenia
    async onChangeMenoPriezviskoDatumNarodenia(e: XFieldChangeEvent<Klient>) {
        if (this.isAddRow()) {
            if (e.object.meno && e.object.priezvisko && e.object.datumNarodenia) {

                let zastavitSave: boolean = false; // ak sa najde existujuci klient (a otvori sa message box s otazkou), tak zastavime save (nezatvorime formular),
                // ak user po typovani mena klikol priamo na Save button (vtedy idu po sebe 2 eventy - onChange a onClick)
                let resolveOnChangePromise: ((value: boolean | PromiseLike<boolean>) => void) | undefined = undefined;
                this.onChangePromise = new Promise((resolve) => {
                    // standardne by sme mali zavolat "resolve(result)" po ukonceni tejto Promise metody
                    // (metoda resolve meni stav z pending na resolved, nasledkom coho sa zavola kod za "await this.onChangePromise")
                    // tu vsak vyuzijeme drobny hack - ulozime si resolve do lokalnej premennej a zavolame ho az na konci tejto onChange metody (ked uz vieme ci sme zobrazili message box alebo nie)
                    resolveOnChangePromise = resolve;  // Expose this for resolving later
                });

                const datumNarodeniaAsDB: string = dateAsDB(dateFromModel(e.object.datumNarodenia));
                // najprv skusime najst klienta noclaharne
                let filterDatum: XCustomFilter;
                if (e.object.datumNarodeniaIbaRok) {
                    // mame len rok, musi sediet rok
                    filterDatum = {where: `date_trunc('year', [datumNarodenia])::DATE = date_trunc('year', ${datumNarodeniaAsDB})::DATE`, params: {}};
                }
                else {
                    // mame cely datum narodenia - ak je v zazname uvedeny rok, tak musi sediet rok, ak je v zazname cely datum, tak musi sediet cely datum)
                    filterDatum = {where: `(([datumNarodeniaIbaRok] AND date_trunc('year', [datumNarodenia])::DATE = date_trunc('year', ${datumNarodeniaAsDB}))` +
                            ` OR ((NOT [datumNarodeniaIbaRok]) AND [datumNarodenia] = ${datumNarodeniaAsDB}))`, params: {}};
                }
                const filter: XCustomFilter | undefined = XUtilsCommon.filterAnd(
                    {where: `${Utils.getSchema()}.unaccent([meno]) ILIKE ${Utils.getSchema()}.unaccent(:meno)` +
                            ` AND ${Utils.getSchema()}.unaccent([priezvisko]) ILIKE ${Utils.getSchema()}.unaccent(:priezvisko)` +
                            ` AND [noclaharen] = TRUE`, params: {meno: e.object.meno, priezvisko: e.object.priezvisko}},
                    filterDatum
                );
                // zosortujeme podla "id desc" - najnovsie zaznamy ako prve
                const klientList: Klient[] = await XUtils.fetchRows('Klient', filter, "id desc",
                    ["pohlavie.name", "obec.okres.nazov", "modifXUser.name", "klientZakazUbytovatList.id"]);
                if (klientList.length > 0) {
                    const klient: Klient = klientList[0];
                    zastavitSave = true; // kedze otvarame message box, tak zastavime save (ak user odisiel z inputu priamo na Save button)
                    if (window.confirm(`Našiel sa záznam pre klienta "${Utils.klientCreateIDInfo(klient)}" pre aktuálnu službu ${Utils.getCurrentSluzba()?.nazov}.${XUtilsCommon.newLine}`
                        + `${klientList.length > 1 ? `Počet nájdených záznamov: ${klientList.length}${XUtilsCommon.newLine}` : ``}`
                        + XUtilsCommon.newLine
                        + `OK - použiť existujúci nájdený záznam (zadané údaje budú prepísané)${XUtilsCommon.newLine}`
                        + `Cancel - pokračovať vo vytváraní nového záznamu`)) {
                        // prejdeme do (plneho) update rezimu
                        this.ponechajDatumNarodeniaAkPrisielRok(klient, e.object.datumNarodenia, e.object.datumNarodeniaIbaRok);
                        this.setState({object: klient});
                    }
                }
                else {
                    // nenasiel sa klient noclaharne, skusime najst klienta v inych sluzbach
                    let filterDatum: XCustomFilter;
                    if (e.object.datumNarodeniaIbaRok) {
                        // mame len rok, musi sediet rok
                        filterDatum = {where: `date_trunc('year', [klient.datumNarodenia])::DATE = date_trunc('year', ${datumNarodeniaAsDB})::DATE`, params: {}};
                    }
                    else {
                        // mame cely datum narodenia - ak je v zazname uvedeny rok, tak musi sediet rok, ak je v zazname cely datum, tak musi sediet cely datum)
                        filterDatum = {where: `(([klient.datumNarodeniaIbaRok] AND date_trunc('year', [klient.datumNarodenia])::DATE = date_trunc('year', ${datumNarodeniaAsDB}))` +
                                ` OR ((NOT [klient.datumNarodeniaIbaRok]) AND [klient.datumNarodenia] = ${datumNarodeniaAsDB}))`, params: {}};
                    }
                    const filter: XCustomFilter | undefined = XUtilsCommon.filterAnd(
                        {where: `${Utils.getSchema()}.unaccent([klient.meno]) ILIKE ${Utils.getSchema()}.unaccent(:meno)` +
                                ` AND ${Utils.getSchema()}.unaccent([klient.priezvisko]) ILIKE ${Utils.getSchema()}.unaccent(:priezvisko)`, params: {meno: e.object.meno, priezvisko: e.object.priezvisko}},
                        filterDatum
                    );
                    // zosortujeme podla "id desc" - najnovsie zaznamy ako prve
                    const klientSluzbaList: KlientSluzba[] = await XUtils.fetchRows('KlientSluzba', filter, "id desc",
                        ["sluzba.kod", "klient.pohlavie.name", "klient.obec.okres.nazov", "klient.modifXUser.name", "klientSluzbaZakazList.id", "klient.klientSluzbaList.sluzba.nazov", "klient.klientZakazUbytovatList.id"]);
                    if (klientSluzbaList.length > 0) {
                        // mame len zaznamy z cudzich sluzieb - pouzijeme len cast Klient z prveho zaznamu
                        const klient: Klient = klientSluzbaList[0].klient;
                        // zratame vsetkych najdenych (kozmeticka zalezitost)
                        const klientIdSet: Set<number> = new Set<number>();
                        for (const klientSluzba of klientSluzbaList) {
                            klientIdSet.add(klientSluzba.klient.id);
                        }
                        zastavitSave = true;
                        if (window.confirm(`Našiel sa záznam pre klienta "${Utils.klientCreateIDInfo(klient)}" (služby: ${klient.klientSluzbaList.map((value: KlientSluzba) => value.sluzba.nazov).join(", ")}).${XUtilsCommon.newLine}`
                            + `${klientIdSet.size > 1 ? `Počet nájdených záznamov: ${klientIdSet.size}${XUtilsCommon.newLine}` : ``}`
                            + XUtilsCommon.newLine
                            + `OK - použiť existujúci nájdený záznam (zadané zdieľané údaje budú prepísané)${XUtilsCommon.newLine}`
                            + `Cancel - pokračovať vo vytváraní nového záznamu`)) {
                            // zapiseme zaznam Klient a nastavime priznak noclaharen = true
                            // ak otvarame klienta vyhladaneho cez KlientNoclaharenAutoComplete tak je to klient z cudzej sluzby a treba mu nastavit priznak noclaharen
                            if (!klient.noclaharen) {
                                klient.noclaharen = true;
                                // a pridame aj predplatene noci
                                klient.predplateneNoci = this.predplateneNoci!;
                            }
                            this.ponechajDatumNarodeniaAkPrisielRok(klient, e.object.datumNarodenia, e.object.datumNarodeniaIbaRok);
                            this.setState({object: klient});
                        }
                    }
                }

                if (resolveOnChangePromise) {
                    // ukoncime this.onChangePromise zavolanim resolve metody (kedze sme volali await tak resolveOnChangePromise by mal byt vzdy nastaveny (system spustil (asynchronnu) metodu objektu Promise)
                    // netusim, preco tu musim explicitne castovat len preto ze som pridal if
                    (resolveOnChangePromise as ((value: boolean | PromiseLike<boolean>) => void))(zastavitSave);
                }
                else {
                    if (zastavitSave) {
                        // toto by sa nemalo stat, ak ano tak nam tento mechanizmus na zastavenie save nezafunguje (a user vytvori duplicitneho klienta)
                        console.log("Neocakavana chyba - resolveOnChangePromise je undefined a zastavitSave je true");
                    }
                }
                this.onChangePromise = undefined; // vratime do povodneho stavu (dolezite je to pre usecase ak resolveOnChangePromise = undefined, vtedy by sa onClickSave nedockal ukoncenia onChangePromise - ten usecase by aj tak nemal nastat...)
            } // if (e.object.meno && e.object.priezvisko && e.object.datumNarodenia)
        }
    }

    // pomocna metodka - zapise datumNarodenia do "najdenyKlient" ak je v klientovi len rok (rok narodenia nam neprepise datum narodenia)
    ponechajDatumNarodeniaAkPrisielRok(najdenyKlient: Klient, datumNarodenia: Date, datumNarodeniaIbaRok: boolean) {
        if (najdenyKlient.datumNarodeniaIbaRok && !datumNarodeniaIbaRok) {
            najdenyKlient.datumNarodenia = datumNarodenia;
            najdenyKlient.datumNarodeniaIbaRok = false;
        }
    }

    async validate(klient: Klient): Promise<XErrors> {
        const errors: XErrors = {};

        // viac ako 14 dnovy zakaz moze udelit len Veduci
        for (const klientZakazUbytovat of klient.klientZakazUbytovatList) {
            const datumOd: Date | null = dateFromModel(klientZakazUbytovat.datumOd);
            const datumDo: Date | null = dateFromModel(klientZakazUbytovat.datumDo);
            if (datumOd !== null && datumDo !== null) {
                const zakazPocetDni: number = UtilsCommon.dateDiff(datumOd, datumDo)! + 1;
                if (zakazPocetDni > 14) {
                    // skontrolujeme ci user zmenil niektory z datumov
                    const zakazDatumy: ZakazDatumy | undefined = this.zakazyMapFromEditStart!.get(klientZakazUbytovat.id);
                    if (zakazDatumy === undefined || !XUtilsCommon.dateEquals(datumOd, dateFromModel(zakazDatumy.datumOd)) || !XUtilsCommon.dateEquals(datumDo, dateFromModel(zakazDatumy.datumDo))) {
                        // mame novy zaznam alebo sa zmenil jeden z datumov
                        if (!Utils.userMaSluzbuRolu(SluzbaEnum.noclaharen, Rola.veduci)) {
                            //zapiseme chyby do zaznamu zapis do specialneho technickeho atributu
                            XFormBase.saveErrorsIntoXRowTechData(klientZakazUbytovat, {datumDo: "Viac ako 14 dňové prerušenie môže zapísať len používateľ v roli Vedúci"});
                        }
                    }
                }
            }
        }

        return errors;
    }

    async onClickSave() {
        // ak zobrazime message box ohladne existujuceho klienta, tak nechceme vykonat save a nechceme ani zatvorit formular
        // kedze onChangeKlientMenoPriezviskoDatumNarodenia ma v sebe await a teda skonci neskor ako tento onClickSave,
        // tak pomocou this.onChangePromise pockame kym dobehne onChangeKlientMenoPriezviskoDatumNarodenia
        if (this.onChangePromise) {
            const zastavitSave: boolean = await this.onChangePromise;
            if (zastavitSave) {
                return;
            }
        }
        super.onClickSave();
    }

    async onClickCancel() {
        // podobne ako v onClickSave nezatvorime formular ak nam onChange zobrazi message box
        if (this.onChangePromise) {
            const zastavitSave: boolean = await this.onChangePromise;
            if (zastavitSave) {
                return;
            }
        }
        super.onClickCancel();
    }

    // TODO - duplicitny kod - do spolocnej nadtriedy
    // overridneme standardny saveRow
    async saveRow(): Promise<Klient> {
        const reload: boolean = this.props.onSaveOrCancel !== undefined;
        const klient: Klient = await XUtils.fetch('saveRow', {entity: this.getEntity(), object: this.state.object, reload: reload});
        if (reload) {
            // ak robime reload (napr. editujeme objekt cez XAutoComplete), tak nam vracia zlu hodnotu derivovaneho atributu menoPriezviskoPrezyvka
            // je to len taka kozmeticka vec, aby sme videli v XAutoComplete spravnu hodnotu
            // zodpoveda funkcii v databaze
            klient.menoPriezviskoPrezyvka = `${stringAsUI(klient.meno)} ${stringAsUI(klient.priezvisko)} ${stringAsUI(klient.prezyvka)}`.trim();
        }
        return klient;
    }

    onChangeDatumNarodeniaIbaRok(e: XFieldChangeEvent<Klient>) {
        if (e.object.datumNarodeniaIbaRok) {
            const datumNarodenia: Date | null = dateFromModel(e.object.datumNarodenia);
            if (datumNarodenia && (datumNarodenia.getMonth() !== 0 || datumNarodenia.getDay() !== 1)) {
                e.object.datumNarodenia = new Date(`${datumNarodenia.getFullYear()}-01-01`);
            }
        }
    }

    onChangeUzivatel(e: XFieldChangeEvent<Klient>) {
        if (e.object.uzivatel) {
            e.object.testovany = Utils.todayNoclaharen();
        }
        else {
            e.object.testovany = null;
        }
    }

    render() {
        return (
            <div>
                <XFormHeader form={this} label={Utils.klientCreateLabel("Klient nocľahárne", this.getKlient())}/>
                <XFormScrollable form={this} widthFitContent={true}>
                    <XFormRow>{/* tato najvrchnejsia uroven XFormRow/XFormCol je tu na to aby ohranicila XInputTextarea "poznamky", inac by bol roztiahnuty na cely formular */}
                        <XFormCol>
                            <XFormRow>
                                <XFormCol labelStyle={{width:'10rem'}}>
                                    <XInputText form={this} field="meno" label="Meno" inputStyle={{width:'15rem'}} onChange={this.onChangeMenoPriezviskoDatumNarodenia}/>
                                    <XInputText form={this} field="priezvisko" label="Priezvisko" inputStyle={{width:'15rem'}} onChange={this.onChangeMenoPriezviskoDatumNarodenia}/>
                                    <XInputText form={this} field="prezyvka" label="Prezývka" inputStyle={{width:'15rem'}}/>
                                    <XInputText form={this} field="rodneCislo" label="Rodné číslo" inputStyle={{width:'7rem'}} placeholder="000000/0000"/>
                                    <div className="x-form-inline-row">
                                        <XInputDate form={this} field="datumNarodenia" label="Dátum narod." labelStyle={{width:'10rem'}} onChange={this.onChangeMenoPriezviskoDatumNarodenia}
                                                    scale={this.state.object?.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date}/>
                                        <XCheckbox form={this} field="datumNarodeniaIbaRok" label="iba rok" labelStyle={{width:'5rem'}} onChange={this.onChangeDatumNarodeniaIbaRok}/>
                                    </div>
                                    <XDropdownEnum form={this} assocField="pohlavie" label="Pohlavie" enumEnumCode={EnumEnum.pohlavie}/>
                                </XFormCol>
                                <XFormCol>
                                    <KlientAdresa form={this} osetrovna={false} labelStyle={{width:'9rem'}}/>
                                </XFormCol>
                                <XFormCol labelStyle={{width:'11rem'}}>
                                    <XInputText form={this} field="cisloVreca" label="Číslo vreca" readOnly={true} inputStyle={{width:'5rem'}}/>
                                    <XInputText form={this} field="predplateneNoci" label="Predplatené noci" inputStyle={{width:'5rem'}} readOnly={!Utils.userMaSluzbuRolu(SluzbaEnum.noclaharen, Rola.veduci, Rola.socialnyPracovnik)}/>
                                    <XFormInlineRow>
                                        <XCheckbox form={this} field="uzivatel" label="Užívateľ" labelStyle={{width:'11rem'}} onChange={this.onChangeUzivatel}/>
                                        <XInputDate form={this} field="testovany" label="Testovaný" readOnly={true} labelStyle={{width:'7rem'}}/>
                                    </XFormInlineRow>
                                    <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                                    <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                                    <XInputText form={this} field="modifXUser.name" label="Modifikoval" inputStyle={{width:'12.35rem'}}/>
                                </XFormCol>
                            </XFormRow>
                            <XFormRow>
                                <XFormCol width="full">
                                    <XInputTextarea form={this} field="poznamky" label="Poznámka" labelOnTop={true} cols="full" autoResize={true}/>
                                </XFormCol>
                            </XFormRow>
                            <XFormDataTable2 form={this} assocField="klientZakazUbytovatList" label="Prerušenia služby" addRowLabel="Pridať prerušenie">
                                <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                                <XFormColumn field="datumOd" header="Dátum od"/>
                                <XFormColumn field="datumDo" header="Dátum do"/>
                                <XFormTextareaColumn field="poznamka" header="Poznámka" width="54rem"/>
                            </XFormDataTable2>
                        </XFormCol>
                    </XFormRow>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
