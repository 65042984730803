import {XEnum} from "../model/user/x-enum.entity";

export enum Param {
    // n - noclaharen
    n_zmluvyPlatneOd = "n_zmluvyPlatneOd",
    n_exspiraciaVriec = "n_exspiraciaVriec",
    n_maxCisloVreca = "n_maxCisloVreca",
    n_novyKlientPredplateneNoci = "n_novyKlientPredplateneNoci",
    n_denPredlzenyOxHodin = "n_denPredlzenyOxHodin"
}

export enum EnumEnum {
    rola = "rola",
    pohlavie = "pohlavie",
    zdravotnaPoistovna = "zdravotnaPoistovna",
    statusVSluzbe = "statusVSluzbe",
    rodinnyStav = "rodinnyStav",
    socialnePostavenie = "socialnePostavenie",
    odkazanostNaSocialnuSluzbu = "odkazanostNaSocialnuSluzbu",
    stupenOdkazanosti = "stupenOdkazanosti",
    druhPrijmu = "druhPrijmu",
    vzdelanie = "vzdelanie",
    mestskaCast = "mestskaCast",
    typCinnosti = "typCinnosti",
    cinnostTypZaznamu = "cinnostTypZaznamu",
    palivoVIgnise = "palivoVIgnise",
    druhStravy = "druhStravy",
    sposobUkoncenia = "sposobUkoncenia",
    noclaharenPlatba = "noclaharenPlatba",
    uzivatelSluzby = "uzivatelSluzby",
    doklady = "doklady",
    typPrav = "typPrav",
    xGroupByType = "xGroupByType",
    xsStatFieldType = "xsStatFieldType",
    xAggregateFunction = "xAggregateFunction",
    samostatnost = "samostatnost",
}

export enum CinnostTypZaznamu {
    generovany = "generovany",
    manualneVytvoreny = "manualneVytvoreny"
}

export enum TypCinnosti {
    sprchovanie = "sprchovanie",
    vymenaPostelnejBielizne = "vymenaPostelnejBielizne",
    pranieOsobnychVeci = "pranieOsobnychVeci",
    ubytovanie = "ubytovanie"
}

// kedze typescript nepodporuje enum typu object literal (len number alebo string), pouzivame tuto konstrukciu
// typ NoclaharenPlatbaEnumType je potrebny aby NoclaharenPlatbaEnum poskytovala presne moznosti Zaplatil, Nezaplatil, Predplatene
interface NoclaharenPlatbaEnumType {
    Zaplatil: XEnum;
    Nezaplatil: XEnum;
    Predplatene: XEnum;
}

// cast "as XEnum" pouzivame aby sme nemuseli zadavat atributy enabled, readOnly, enumOrder, xEnumEnum
export const NoclaharenPlatbaEnum: NoclaharenPlatbaEnumType = {
    Zaplatil: {id: 96, code: 'zaplatil', name: 'Zaplatil'} as XEnum,
    Nezaplatil: {id: 97, code: 'nezaplatil', name: 'Nezaplatil'} as XEnum,
    Predplatene: {id: 98, code: 'predplatene', name: 'Predplatené'} as XEnum
} as const

export enum TypPrav {
    lenCheckbox = "lenCheckbox",
    citanieZapis = "citanieZapis"
}

export enum SluzbaRolaPravaKod {
    zapisPanelSpecPorad = "zapisPanelSpecPorad",
    zapisPanelPsychPorad = "zapisPanelPsychPorad"
}

// zatial tieto pouzivame v kode
export enum Rola {
    veduci = "veduci",
    pracovnik = "pracovnik",
    socialnyPracovnik = "socialnyPracovnik",
    specPoradPracovnik = "specPoradPracovnik",
    psychPoradPracovnik = "psychPoradPracovnik"
}

// pouzivame nazov SluzbaEnum aby sme odlisili od entity Sluzba
export enum SluzbaEnum {
    utulokLujza = "utulokLujza",
    utulokZOS = "utulokZOS",
    utulokVincent = "utulokVincent",
    noclaharen = "noclaharen",
    osetrovnaNDC = "osetrovna",
    streetwork = "streetwork",
    IPB = "IPB",
    komunitneOrganizovanie = "komunitneOrganizovanie",
    komunitneCentrumZahorskaVes = "komunitneCentrumZahorskaVes",
    vincentPoradenstvo = "vincentPoradenstvo",
    vincentOsetrovna = "vincentOsetrovna",
    vincentPrepravnaSluzba = "vincentPrepravnaSluzba",
    alzbetaOsetrovna = "alzbetaOsetrovna"
}

export enum TypPorad {
    specPorad = "specPorad",
    psychPorad = "psychPorad"
}

// zodpoveda XEnum xGroupByType
// TODO - do lib-ky
export enum XGroupByType {
    entityEnum = "entityEnum",
    xEnum = "xEnum",
    day = "day",
    month = "month",
    year = "year",
    age = "age",
    intervalSet = "intervalSet",
    value = "value"
}

// zodpoveda XEnum xsStatFieldType
// TODO - do lib-ky
export enum XsStatFieldType {
    rowCount = "rowCount",
    aggregateField = "aggregateField",
    fieldSetRowCount = "fieldSetRowCount",
    fieldSetFieldCount = "fieldSetFieldCount",
    fieldSetAggregateField = "fieldSetAggregateField"
}
